export default () => ({
  busy: false,
  length: 6,
  countdown: 0,
  value: "",
  handleInput(e) {
    const input = e.target;
    this.value = Array.from(Array(this.length), (element, i) => {
      return this.$refs[i].value || "";
    }).join("");

    if (input.nextElementSibling && input.value) {
      input.nextElementSibling.focus();
      input.nextElementSibling.select();
    }
  },
  handlePaste(e) {
    const paste = e.clipboardData.getData("text");
    this.value = paste;
    const inputs = Array.from(Array(this.length));
    inputs.forEach((element, i) => {
      this.$refs[i].value = paste[i] || "";
    });
  },
  handleBackspace(e) {
    const previous = parseInt(e, 10) - 1;
    this.$refs[previous] && this.$refs[previous].focus();
  },

  async resendOtp(event) {
    event.preventDefault();
    this.busy = true;

    await fetch("/otp/resend", {
      credentials: "same-origin",
      method: "post",
    });

    setTimeout(() => (this.busy = false), 30 * 1000);
  },
});
