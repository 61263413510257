export default () => ({
  async copy() {
    if (await this.performCopy()) {
      this.$dispatch("notice-success", "Your key was copied successfully.");
    } else {
      this.$dispatch(
        "notice-error",
        "Could not copy the requested key, please check your clipboard permissions or just copy it manually."
      );
    }
  },
  async performCopy() {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(this.$refs.keyHolder.value);
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    }

    // Fallback for old browsers
    this.$refs.keyHolder.focus();
    this.$refs.keyHolder.select();
    return document.execCommand("copy");
  },
});
