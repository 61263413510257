export default function popupHandler(imagePath) {
  return {
    showPopup: false,
    storageKey: 'popup_seen',
    imageData: { file_name: imagePath, seen: false },
    init() {
        const storedData = JSON.parse(localStorage.getItem(this.storageKey));
        if (!storedData || !storedData[imagePath] || !storedData[imagePath].seen) {
            this.showPopup = true;
        }
    },
    closePopup() {
        this.showPopup = false;
        let storedData = JSON.parse(localStorage.getItem(this.storageKey)) || {};
        storedData[imagePath] = { file_name: imagePath, seen: true };
        localStorage.setItem(this.storageKey, JSON.stringify(storedData));
    }
  };
}
