import _ from "lodash";

export default function () {
  return {
    shown: false,
    isApplied: false,
    displayValue: null,
    fields: {},
    field: null,
    field_dir: "asc",
    init() {
      this.$watch(
        "filters",
        (filters) => (this.isApplied = "sorted" in filters)
      );
      this.$watch("isApplied", this.updateDisplayValue.bind(this));
      this.$watch("field", this.updateDisplayValue.bind(this));
      this.$watch("field_dir", this.updateDisplayValue.bind(this));

      this.fields = JSON.parse(this.$root.dataset.fields);
      this.$nextTick(() => this.updateFromUpstream());
    },
    updateFromUpstream() {
      let field = _(this.filters).get(`sorted.field`);
      if (!_(this.fields).values().includes(field)) {
        field = null;
      }

      this.field = _(this.filters).get(`sorted.field`) || null;

      let dir = _(this.filters).get(`sorted.dir`) || "asc";
      if (!["asc", "desc"].includes(dir)) {
        dir = "asc";
      }

      this.field_dir = dir;
    },
    updateDisplayValue(isApplied) {
      this.displayValue = isApplied ? this.sortText() : null;
    },
    sortText() {
      if (!this.fields[this.field]) return;

      const dir = this.field_dir === "asc" ? "ascending" : "descending";
      return ` by ${this.fields[this.field].toLowerCase()}, ${dir}`;
    },
    show() {
      this.shown = true;
    },
    hide() {
      this.shown = false;
      this.$nextTick(() => this.updateFromUpstream());
    },
    isSelected(key, dir) {
      return this.field === key && this.field_dir === dir;
    },
    apply(key, dir) {
      if (this.isSelected(key, dir)) {
        delete this.filters["sorted"];
        this.hide();
        return;
      }

      this.filters["sorted"] = {
        field: (this.field = key),
        dir: (this.field_dir = dir),
      };

      this.hide();
		this.$nextTick(() => this.submit());
    },
    get sortFilters() {
      return _.chain(this.fields)
        .keys()
        .map((f) => [
          [f, "asc"],
          [f, "desc"],
        ])
        .flatten()
        .value();
    },
  };
}
