import _ from "lodash";

export default function () {
  return {
    field: null,
    title: null,
    shown: false,
    mode: "equal", // equal, between, gte, lte
    firstValue: null,
    secondValue: null,
    isApplied: false,

    init() {
      this.field = this.$root.dataset.field;
      this.title = this.$root.dataset.title;
      this.$watch(
        "filters",
        (filters) => (this.isApplied = this.field in filters)
      );
      this.updateFromUpstream();
    },

    get displayValue() {
      return this.value;
    },

    updateFromUpstream() {
      this.$nextTick(() => {
        const f = (this.firstValue = this.upstreamFirstValue());
        const s = (this.secondValue = this.upstreamSecondValue());

        if (!(s && s)) {
          return;
        }

        if (f === s) {
          this.mode = "equal";
        } else if (!s) {
          this.mode = "gte";
        } else if (!f) {
          this.mode = "lte";
        } else {
          this.mode = "between";
        }
      });
    },
    upstreamFirstValue() {
      return _(this.filters).get(`${this.field}.gte`) || null;
    },
    upstreamSecondValue() {
      return _(this.filters).get(`${this.field}.lte`) || null;
    },
    show() {
      this.shown = true;
    },
    hide() {
      this.shown = false;
      this.updateFromUpstream();
    },
    firstToApply() {
      let date = this.firstValue;

      if (this.mode === "lte") {
        date = null;
      }

      return date;
    },
    secondToApply() {
      let date = this.secondValue;

      if (this.mode === "equal") {
        date = this.firstToApply();
      }

      if (this.mode === "gte") {
        date = null;
      }

      return date;
    },
    apply() {
      const refs = [this.$refs.first, this.$refs.second];

      if (!refs.filter(_.isElement).every((r) => r.reportValidity())) {
        return;
      }

      let filters = {
        gte: this.firstToApply(),
        lte: this.secondToApply(),
      };

      filters = _(filters).omitBy(_.isEmpty).value();

      if (!_.isEmpty(filters)) {
        this.filters[this.field] = filters;
      } else {
        delete this.filters[this.field];
      }

      this.hide();
    },
    remove() {
      this.firstValue = null;
      this.secondValue = null;
      delete this.filters[this.field];
      this.hide();
    },
  };
}
