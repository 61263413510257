import colors from "./colors";

export default (ctx, label, labels, data) => {
  const gradient = ctx.createLinearGradient(0, 0, 0, 1000);
  gradient.addColorStop(0, colors.color.quarter);
  gradient.addColorStop(1, colors.color.zero);

  return {
    type: "line",
    data: {
      labels: labels,
      datasets: [
        {
          label: label,
          data: data,
          fill: true,
          backgroundColor: gradient,
          pointBackgroundColor: colors.color.default,
          borderColor: colors.color.half,
          lineTension: 0.13,
          borderWidth: 2,
          pointRadius: 3,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      responsive: true,
      tension: 0.4,
      scales: {
        y: {
          beginAtZero: true,
          grace: "15%",
          border: {
            dash: [4, 4],
          },
          grid: {
            color: colors.color.border,
          },
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
    },
  };
};
