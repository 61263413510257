import Loader from "../loader";

export default function () {

  return {
    filters: {},
    applied: {},
    method: "get",
     debouncedSubmit: null,
    init() {
      this.$nextTick(() => {
        this.filters = this.previousScope();
        this.applied = this.previousScope();
      });
      this.debouncedSubmit = _.debounce(() => {
        this.doSubmit();
      }, 2500);
    },
     submit() {
      this.debouncedSubmit();
    },
     doSubmit() {
      Loader.show();
      document.location.search = _(this.filters)
        .entries()
        .transform((result, pair) => {
          if (pair[1] instanceof Array) {
            pair[1].forEach((v) => result.push([pair[0] + "[]", v]));
          } else if (typeof pair[1] === "object") {
            _(pair[1]).forIn((subValue, subKey) =>
              result.push([`${pair[0]}[${subKey}]`, subTagName])
            );
          } else {
            result.push(pair);
          }
        })
        .map((pair) => pair.join("="))
        .join("&");
    },
    resetFilters() {
      this.filters = {};
        this.doSubmit(); 
    },
    isFieldModified(field) {
      return (
        field in this.filters && this.filters[field] !== this.applied[field]
      );
    },
    isFieldNew(field) {
      return field in this.filters && !(field in this.applied);
    },
    previousScope() {
      const dataset = this.$root.dataset;
      let applied = {};
      try {
        applied = JSON.parse(dataset.applied);
      } catch {}
      if (typeof this.applied !== "object") {
        applied = {};
      }

      return applied;
    },
    get isTouched() {
      return !_.isEqual(this.filters, this.applied);
    },
    get empty() {
      return Object.entries(this.filters).length === 0;
    },
  };
}
