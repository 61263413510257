import _ from "lodash";

export default function () {
  return {
    field: null,
    title: null,
    multiple: false,
    shown: false,
    isApplied: false,
    values: [],
    init() {
      const data = this.$root.dataset;
      this.field = data.field;
      this.title = data.title;
      this.multiple = data.multiple === "true";
      this.$watch(
        "filters",
        (filters) => (this.isApplied = this.field in filters)
      );
      this.$nextTick(() => this.updateFromUpstream());
    },
    get displayValue() {
      return _.chain(this.values)
        .map((e) => e.value)
        .join(", ")
        .truncate()
        .value();
    },
    updateFromUpstream() {
      let upstream = this.filters[this.field] || [];

      if (!(upstream instanceof Array)) {
        upstream = [upstream];
      }

      if (upstream.length === 0) {
        upstream.push("");
      }

      if (!this.multiple) {
        upstream = upstream.slice(0, 1);
      }

      this.values = upstream.map((v) => this.newInputEntry(v));
    },
    show() {
      this.shown = true;
    },
    hide() {
      this.shown = false;
      this.$nextTick(() => this.updateFromUpstream());
    },
    apply() {
      let valueToApple = this.values
        .map((e) => e.value.trim())
        .filter((e) => e.length > 0);

      if (valueToApple.length === 1) {
        valueToApple = valueToApple[0];
      }

      if (valueToApple.length > 0) {
        this.filters[this.field] = valueToApple;
      } else {
        delete this.filters[this.field];
      }
		
		this.hide();
      this.$nextTick(() => this.submit());
    },
    remove() {
      delete this.filters[this.field];
      this.hide();
		this.$nextTick(() => this.doSubmit());
    },
    newInputEntry(value = "") {
      return { id: this.randomKey(), value: value };
    },
    randomKey() {
      return Array(32)
        .fill(0)
        .map((_) => ((Math.random() * 16) | 0).toString(16))
        .join("");
    },
    addNewInputEntry(value = "") {
      this.values.push(this.newInputEntry(value));
    },
    async onInput(event) {
      if (!this.multiple || event.inputType !== "insertFromPaste") {
        return;
      }
      let clipboardText = null;
      try {
        clipboardText = await navigator.clipboard.readText();
      } catch {
        // Ignore when permission is not given
        return;
      }

      if (!clipboardText.match(/\n/)) {
        return;
      }

      const fragments = clipboardText
        .split(/\n+/)
        .map((f) => f.trim())
        .filter((f) => f.length > 0);

      for (const f of fragments) {
        this.addNewInputEntry(f);
      }

      const additionalText = clipboardText
        .replace(/^.+\n/, "")
        .replace(/\n/g, " ");

      event.target.value = event.target.value
        .replace(additionalText, "")
        .trim();
    },
  };
}
