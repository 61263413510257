import { DateTime } from "luxon";
export default function dateToUtc(oldvalue = '') {
    return {
        init() {
            this.$nextTick(() => {
                this.initDatePicker();
            });

            if(this.isValidDate(oldvalue)){
                this.expired_at = DateTime.fromISO(oldvalue).toFormat("yyyy-MM-dd HH:mm");
            }else{
                this.expired_at = '';
            }
        },
        expired_at: '',
        isValidDate(dateString) {
            const date = new Date(dateString);
            return !isNaN(date.getTime());
        },
		  initDatePicker(){
			const el = this.$refs.createInvoicePicker;
            if (el) {
               this.flatpickrInstance = flatpickr(el, {
						defaultDate: this.expired_at,
						minDate: new Date(),
						enableTime: true,
						time_24hr: true
               });
            }
		  },
        get zulu_expired_at() {
            try{
                return DateTime.fromFormat(this.expired_at, 'yyyy-MM-dd HH:mm').toUTC().toISO();
            }catch(e){
                console.log(e)
                return '';
            }
        }
    }
}
