import makeDefaultOptions from "./default-line-options";
import Chart from "chart.js/auto";

export default () => {
  const el = document.getElementById("payment-amount-monthly");
  if (!el) {
    return;
  }

  const ctx = el.getContext("2d");

  let options = makeDefaultOptions(
    ctx,
    chart_data.payments_amount.monthly.label,
    chart_data.payments_amount.monthly.labels,
    chart_data.payments_amount.monthly.data
  );

  const chart = new Chart(ctx, options);
  chart.render();
};
