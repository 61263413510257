export default function() {
  function systemTheme() {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  }

  return {
    theme: this.$persist(systemTheme()),
    toggleTheme() {
      this.theme = (this.theme === 'dark' ? 'light' : 'dark');
    }
  }
}
