import initInvoiceCountDaily from "./invoice-count-daily";
import initInvoiceCountWeekly from "./invoice-count-weekly";
import initInvoiceCountMonthly from "./invoice-count-monthly";
import initPaymentCountDaily from "./payment-count-daily";
import initPaymentCountWeekly from "./payment-count-weekly";
import initPaymentCountMonthly from "./payment-count-monthly";
import initPaymentAmountDaily from "./payment-amount-daily";
import initPaymentAmountWeekly from "./payment-amount-weekly";
import initPaymentAmountMonthly from "./payment-amount-monthly";

document.addEventListener("DOMContentLoaded", () => {
  if (window.chartsLoaded) {
    return;
  }

  initInvoiceCountDaily();
  initInvoiceCountWeekly();
  initInvoiceCountMonthly();
  initPaymentCountDaily();
  initPaymentCountWeekly();
  initPaymentCountMonthly();
  initPaymentAmountDaily();
  initPaymentAmountWeekly();
  initPaymentAmountMonthly();

  window.chartsLoaded = true;
});
