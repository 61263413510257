import makeDefaultOptions from "./default-bar-options";
import Chart from "chart.js/auto";

export default () => {
  const el = document.getElementById("payment-count-monthly");
  if (!el) {
    return;
  }

  const ctx = el.getContext("2d");

  let options = makeDefaultOptions(
    ctx,
    chart_data.payments_count.monthly.label,
    chart_data.payments_count.monthly.labels,
    chart_data.payments_count.monthly.data
  );

  const chart = new Chart(ctx, options);

  chart.render();
};
