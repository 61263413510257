export default function metadataValidator(form) {
    return {
        busy: false,
        form: form,
        metadata_errors: [],
        has_duplicates: false,
        submitForm(){
            if(!this.findDuplicates() && this.validate_inputs()){
                this.busy = true;
                this.form.submit();
            }
        },
        findDuplicates() {
            console.log("submit form")
            const metadata_form = new FormData(this.form)
            const form_keys = metadata_form.getAll('metadata[][key]')

            const metadata_keys = form_keys.map(element => {
                return element.trim();
            });

            if (!metadata_keys) {
                this.busy = false;
                return false;
            }

            this.has_duplicates = metadata_keys.some((m, index) => {
                if (m.length === 0) {
                    return false;
                }
                return metadata_keys.indexOf(m) !== index
            })

            return this.has_duplicates;
        },
        validate_inputs() {
            this.metadata_errors = [];
            const metadata_form = new FormData(this.form);
            const form_keys = metadata_form.getAll('metadata[][key]');
            const form_values = metadata_form.getAll('metadata[][value]');
            const metadata_keys = form_keys.map(element => { return element.trim(); });
            const metadata_values = form_values.map(element => { return element.trim(); });

            for (let i = 0; i < metadata_keys.length; i++) {
                if (metadata_keys[i].length === 0 && metadata_values[i].length > 0) {
                    this.metadata_errors.push(`Metadata ${[i]}: You can't have values without keys`);
                } else if (metadata_keys[i].length > 0 && metadata_values[i].length === 0) {
                    this.metadata_errors.push(`Metadata ${[i]}: You can't have keys without values`);
                }
            }

            return this.metadata_errors.length === 0;
        }
    }

}