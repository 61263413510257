const theme = localStorage.getItem("theme");

export default {
  color: {
    default: theme == "dark" ? "rgba(2,160,255,0.75)" : "rgba(2,160,255,.75)",
    half: theme == "dark" ? "rgba(2,160,255,0.50)" : "rgba(2,160,255,0.50)",
    quarter: theme == "dark" ? "rgba(2,160,255,0.25)" : "rgba(2,160,255,0.25)",
    zero: theme == "dark" ? "rgba(2,160,255,0)" : "rgba(2,160,255,0)",
    border: theme == "dark" ? "rgba(51,65,85,0.75)" : "rgba(51,65,85,0.25)",
  },
};
