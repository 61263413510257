export default function () {
  return {
    value: null,
    displayValue: null,
    isApplied: false,
    init() {
      this.$watch(
        "filters",
        (filters) => (this.isApplied = this.field in filters)
      );
      this.updateFromMaster();
    },

    get field() {
      return this.$root.dataset.field;
    },
    get title() {
      const dataset = this.$root.dataset;

      if (this.value === null) {
        return dataset.title;
      }

      return this.value ? dataset.activeTitle : dataset.inactiveTitle;
    },

    updateFromMaster() {
      this.$nextTick(() => {
        let value = (this.value = this.filters[this.field]);
        if (typeof value !== "boolean") {
          value = null;
        }

        this.value = value;
      });
    },
    show() {
      if (this.value === null) {
        this.value = false;
      }

      this.filters[this.field] = this.value = !this.value;
    },
    remove() {
      this.value = null;
      delete this.filters[this.field];
    },
  };
}
