export default function ipAdder(ips = null) {
  return {
    init() {
      if (ips === null) {
        this.fields.push({
          id: Math.random() * 10000000,
          name: "",
          address: "",
        });
      } else {
        Object.entries(ips).forEach((ip) => {
          this.fields.push({
            id: Math.random() * 10000000,
            name: ip[0],
            address: ip[1],
          });
        });
        this.fields.push({
          id: Math.random() * 10000000,
          name: "",
          address: "",
        });
      }
    },
    fields: [],
    addNewField() {
      this.fields.push({ id: Math.random() * 10000000, name: "", address: "" });
    },
    removeField(id) {
      if (this.fields.length < 2) {
        return;
      }
      this.fields = this.fields.filter((f) => f.id !== id);
    },
  };
}
