export default () => ({
  lastId: -1,
  notices: [],
  closeQueue: [],
  types: {
    success: {
      title: "Success",
    },
    warning: {
      title: "Warning",
    },
    error: {
      title: "Error",
    },
  },
  addNotice(type, message) {
    if (!(type in this.types)) {
      console.error(`Unsupported notice type: ${type}`);
      return;
    }
    const id = ++this.lastId;
    const notice = { id, type, message, shown: true, hideTimeout: null };
    this.notices.push(notice);
    notice.hideTimeout = setTimeout(() => this.closeNotice(id, false), 10_000);
  },
  removeNoticeObject(notice) {
    this.notices = this.notices.filter((n) => n !== notice);
  },
  closeNotice(id, shouldClearTimeout = true) {
    const notice = this.notices.find((n) => n.id === id);
    if (!notice) {
      return;
    }

    if (shouldClearTimeout) {
      clearTimeout(notice.hideTimeout);
    }

    notice.shown = false;
    setTimeout(
      () => this.$nextTick(() => this.removeNoticeObject(notice)),
      4000
    );
  },
});
