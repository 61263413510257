import _ from "lodash";
import { DateTime } from "luxon";
import flatpickr from "flatpickr";

export default function () {
  return {
    field: null,
    title: null,
    shown: false,
    mode: "equal", // equal, between, gt, lt
    firstValue: null,
    secondValue: null,
    isApplied: false,
    init() {
      this.field = this.$root.dataset.field;
      this.title = this.$root.dataset.title;
      this.$watch("filters", (filters) => {
        const gt_field = `${this.field}[gt]`;
        const lt_field = `${this.field}[lt]`;

        this.isApplied = !!(filters[gt_field] || filters[lt_field]);
      });

      this.$watch('mode', () => {
         this.$nextTick(() => this.initDatePickers());
       });

      this.$nextTick(() => {this.updateFromUpstream(); this.initDatePickers()})
    },
    get displayValue() {
      const current_local = document.getElementsByTagName("html")[0].getAttribute('lang') || 'en';

      if (! (this.firstValue || this.secondValue)) {
        return null;
      }

      if (this.mode === "equal") {
        return this.formatForDisplay(this.firstValue);
      }

      if (this.mode === "lt") {
        return `${current_local === 'en' ? 'before' : 'قبل'} ${this.formatForDisplay(this.secondValue)}`;
      }

      if (this.mode === "gt") {
        return `${current_local === 'en' ? 'after' : 'بعد'} ${this.formatForDisplay(this.firstValue)}`;
      }

        if (this.mode === "between") {
          return `${this.formatForDisplay(this.firstValue)} -> ${this.formatForDisplay(this.secondValue)}`;
        }   
    },
    formatForDisplay(date) {
      return DateTime.fromISO(date).toFormat("yyyy-MM-dd");
    },
    updateFromUpstream() {
        const f = this.firstValue = this.upstreamFirstValue();
        const s = this.secondValue = this.upstreamSecondValue();

        if (!(f || s)) {
          return;
        }

        if (f === s) {
          this.mode = "equal";
        } else if (!s) {
          this.mode = "gt";
        } else if (!f) {
          this.mode = "lt";
        } else {
          this.mode = "between";
        }
    },

    initDatePickers() {
      if (this.mode === 'equal' || this.mode === 'gt') {
       flatpickr(this.$refs.firstDatePicker);
      }  
      else if (this.mode === 'between') {
         if (this.$refs.dateFrom && this.$refs.dateTo) {
            this.dateFromPickerInstance = flatpickr(this.$refs.dateFrom, {
              onChange: (selectedDates) => {
                if (this.dateToPickerInstance) {
                  const newMinDate = selectedDates[0] ? new Date(selectedDates[0]).fp_incr(1) : null;
                  this.dateToPickerInstance.set('minDate', newMinDate);
                }
              }
            });
  
            this.dateToPickerInstance = flatpickr(this.$refs.dateTo, {
              onReady: (_, __, fp) => {
                if (this.dateFromPickerInstance.selectedDates[0]) {
                  fp.set('minDate', new Date(this.dateFromPickerInstance.selectedDates[0]).fp_incr(1));
                }
              }
            });
         }
      } 
    },

    upstreamFirstValue() {
      return this.utcTimeToLocal(
        _(this.filters).get(`${this.field}[gt]`) || null
      );
    },
    upstreamSecondValue() {
      return this.utcTimeToLocal(
        _(this.filters).get(`${this.field}[lt]`) || null
      );
    },
    show() {
      this.shown = true;
    },
    hide() {
      this.shown = false;
      this.updateFromUpstream();
    },
    localTimeToUTC(local) {
      const date = DateTime.fromISO(local);
      if (date.invalid !== null) {
        return null;
      }

      return date
        .toUTC()
        .toISO()
        .replace(/.\d{3}Z/, "Z");
    },
    utcTimeToLocal(utc) {
      const date = DateTime.fromISO(utc);
      if (date.invalid !== null) {
        return null;
      }

      return date.toLocal().toFormat("yyyy-MM-dd");
    },
    firstToApply() {
      let date = this.localTimeToUTC(this.firstValue);

      if (this.mode === "lt") {
        date = null;
      }

      return date;
    },
    secondToApply() {
      let date = this.localTimeToUTC(this.secondValue);

      if (this.mode === "equal") {
        date = this.firstToApply();
      }

      if (this.mode === "gt") {
        date = null;
      }

      return date;
    },
    apply() {
      let filters = {
        gt: this.firstToApply(),
        lt: this.secondToApply(),
      };

      filters = _(filters).omitBy(_.isEmpty).value();

      if (!_.isEmpty(filters)) {
        this.filters[`${this.field}[gt]`] = filters.gt;
        this.filters[`${this.field}[lt]`] = filters.lt;
      } else {
        delete this.filters[this.field];
      }

      this.hide();
      this.$nextTick(() => this.submit());
    },
    remove() {
      this.firstValue = null;
      this.secondValue = null;

      delete this.filters[`${this.field}[lt]`];
      delete this.filters[`${this.field}[gt]`];

      this.hide();
      this.$nextTick(() => this.doSubmit());
    },
  };
}
