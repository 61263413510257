import _ from "lodash";

export default function () {
  return {
    shown: false,
    value: [],
    isApplied: false,
    displayValue: null,
    field: null,
    title: null,
    options: {},

    init() {
      const data = this.$root.dataset;
      this.field = data.field;
      this.title = data.title;
      this.options = JSON.parse(data.options);
      this.$watch(
        "filters",
        (filters) => (this.isApplied = this.field in filters)
      );
      this.$watch(
        "isApplied",
        (isApplied) =>
          (this.displayValue = isApplied ? this.previewText() : null)
      );
      this.$nextTick(() => this.updateFromMaster());
    },
    updateFromMaster() {
      this.$nextTick(() => (this.value = this.filters[this.field] || []));
    },
    show() {
      this.shown = true;
    },
    hide() {
      this.shown = false;
      this.$nextTick(() => this.updateFromMaster());
    },
    apply() {
      if (this.value.length > 0) {
        this.filters[this.field] = this.value;
      } else {
        delete this.filters[this.field];
      }
      this.hide();
    },
    remove() {
      this.value = [];
      delete this.filters[this.field];
      this.hide();
    },
    previewText() {
      return _.chain(this.value)
        .map((v) => this.options[v])
        .join(", ")
        .truncate({ length: 24, separator: /,? +/ })
        .value();
    },
  };
}
