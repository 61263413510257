import makeDefaultOptions from "./default-bar-options";
import Chart from "chart.js/auto";

export default () => {
  const el = document.getElementById("invoice-count-daily");
  if (!el) {
    return;
  }

  const ctx = el.getContext("2d");

  let options = makeDefaultOptions(
    ctx,
    chart_data.invoices_count.daily.label,
    chart_data.invoices_count.daily.labels,
    chart_data.invoices_count.daily.data
  );

  const chart = new Chart(ctx, options);
  chart.render();
};
