const Loader = () => {
  const loaderElement = document.getElementById('loader');

  const show = () => {
    loaderElement.classList.remove('hidden');
  };

  return {
    show
  };
};

export default Loader();