import colors from "./colors";

export default (ctx, label, labels, data) => {
  const gradient = ctx.createLinearGradient(0, 0, 0, 1000);
  gradient.addColorStop(0, colors.color.default);
  gradient.addColorStop(1, colors.color.zero);

  return {
    type: "bar",
    data: {
      labels: labels,
      datasets: [
        {
          label: label,
          data: data,
          backgroundColor: gradient,
          borderColor: gradient,
          borderRadius: 4,
          barThickness: 20,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          grace: "15%",
          border: {
            dash: [4, 4],
          },
          grid: {
            color: colors.color.border,
          },
          ticks: {
            // forces step size to be 50 units
            stepSize: 1,
          },
        },
      },
      tension: 0.4,
      responsive: true,
    },
  };
};
