export default (currencies, symbol) => ({
  init() {
	this.currency_symbol(symbol);
},
  all_currencies: currencies,
  current: null,
  currency_symbol(currency) {
    for (let i = 0; i < this.all_currencies.length; i++) {
      if (this.all_currencies[i]["iso_code"] === currency) {
        this.current = this.all_currencies[i]["symbol"];
      }
    }
  },
});
