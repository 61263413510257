export default (src = "") => ({
  imageUrl: src,
  newImageUrl: '',
  fileChosen(event) {
    this.fileToDataUrl(event, (src) => {
      this.imageUrl = src;
      this.newImageUrl = src;
    });
  },
  fileToDataUrl(event, callback) {
    if (!event.target.files.length) {
      return;
    }

    const file = event.target.files[0]
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = (e) => callback(e.target.result);
    reader.onerror = (error) => console.error(error);
  },
});
