export default function metadataAdder(metadata = null) {
  return {
    init() {
      if (metadata === null) {
        this.fields.push({ id: Math.random() * 10000000, key: "", val: ""});
      } else {
        Object.entries(metadata).forEach((m) => {
          this.fields.push({
            id: Math.random() * 10000000,
            key: m[0],
            val: m[1],
          });
        });
      }
    },
    fields: [],
    prepair_for_api() {
      let metadata = {};
      this.fields.forEach((field) => {
			if (!field.isRemoved) {
				metadata[field.key] = field.val;
			} else {
				metadata[field.key] = null;
			}
		});
		return metadata;
    },
    addNewField() {
      this.fields.push({ id: Math.random() * 10000000, key: "", val: ""});
    },
    removeField(id) {
      const index = this.fields.findIndex((f) => f.id === id);
		if (index !== -1) {
			this.fields[index].val = null;
			this.fields[index].isRemoved = true;
		}
    },
  };
}
